@font-face {
  font-family: 'MontserratMedium';
  src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
}

.ant-form-item .ant-form-item-label>label {
  height: auto;
}
.ant-input-number-input-wrap > input.ant-input-number-input {
  text-align: center;
}